body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hiding the spin buttons in Webkit browsers like Chrome, Safari */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0);
  color: white;
  min-height: 10rem;

  a {
    color: #0070f3;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }

  }
}

/* Hiding the spin buttons in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.card {
  display: inline-block;
  background-color: white;
  color: black;
  border-radius: 1em;
  padding: 2em;
  text-align: center;
  min-width: 2em;
  height: 6em;
}

.ticker-container {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.ticker-text {
  display: inline-block;
  padding-left: 100%;
  animation: scroll-left 15s linear infinite;
}

h1 h2 h3 {
  font-family: 'Tw Cen MT';
}

@keyframes scroll-left {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

.player-turn,
.dealer-turn {
  border: 2px solid yellow;
  /* Highlight border */
  animation: pulse 1s infinite;
  /* Add a pulsing animation */
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 0, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 0, 0);
  }
}

.zoom-effect {
  overflow: hidden;
  /* Ensures the image doesn't overflow its container */
}

.zoom-effect img {
  transition: transform 8s ease-in-out;
  /* Smooth transition */
  transform-origin: center;
  /* Ensures zoom effect is centered */
}

.zoom-effect img:hover {
  transform: scale(1.5);
  /* Adjust the scale value to control zoom level */
}

.raffle-image {
  width: 100%;
  height: 100%;
  border: 2px solid white;
  border-radius: 10px;
  max-height: 20em;
  max-width: 24em;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.5);
  transition: transform 0.3s ease-in-out;
}

.raffle-image:hover {
  transform: translateY(-5px);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.5);
}

.ticket {
  background-color: #f08989;
  display: inline-block;
  margin: 10px;
  overflow: hidden;
  position: relative;

  .ticket_border--dotted {
    border: dotted 1px black;
    margin: 14px;
  }

  .ticket__number {
    font-size: 16px;
    font-weight: bold;
    word-wrap: break-word;
    overflow: scroll;
  }

  .ticket__position--bottom {
    bottom: -10px
  }

  .ticket__position--left {
    left: -10px
  }

  .ticket__position--right {
    right: -10px
  }

  .ticket__position--top {
    top: -10px
  }

  .ticket__position--top,
  .ticket__position--bottom,
  .ticket__position--left,
  .ticket__position--right {
    background-color: #FFF;
    border-radius: 100%;
    height: 20px;
    position: absolute;
    width: 20px;
  }

  .ticket__text {
    font-size: 20px;
    font-weight: bold;
    height: 120px;
    line-height: 36px;
    padding-top: 5px;
    text-align: center;
    width: 200px;
    color: black;
  }
}



@tailwind base;

@tailwind components;

@tailwind utilities;